/* global window */
import '../scss/index.scss';

// require('bootstrap/dist/js/bootstrap.bundle');
import 'eldarion-ajax';

import ajaxSendMethod from './ajax';
import CodeMirror from 'codemirror';

$(() => {
  $(document).ajaxSend(ajaxSendMethod);
  $(document).on('change', ':file', function() {
    var input = $(this),
        numFiles = input.get(0).files ? input.get(0).files.length : 1,
        label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.trigger('fileselect', [numFiles, label]);
  });
  $(':file').on('fileselect', function(event, numFiles, label) {
    const $label = $(event.target).parent('label').find('.label');
    const $btn = $(event.target).parent('label').parent('.btn-group').find('button');
    $label.text(label);
    $btn.attr('disabled', false);
  });
  const orig = document.getElementById('id_original') || document.getElementById('id_modified');
  if (orig) {
    CodeMirror.fromTextArea(orig, {
      lineNumbers: true,
      mode: 'plaintext',
      theme: 'tomorrow-night-bright'
    });
  }
});
